/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const pageContent =
  '<gatsby_donation amount="0" btntext="Contribute" donationid="20"' +
  'projectid="" btnbg="#4d8f15" colortext="black" colorprimary="forestgreen"' +
  'colorsecondary="darkgreen" colorbackground="white" colormuted="#f6f6f6"' +
  'colorhighlight="#efeffe" titletext="Heartfulness Mysuru Donation"/>';
const PageContent = React.memo(PageContentNonMemoized);

const GeneralMysoreFund = () => {
  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("mysuru-fund", event),
    []
  );

  return (
    <LayoutHome
      seoTitle="General Hrc Mysuru Fund - Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Heartfulness Donations</h3>}
    >
      <Box>
        <h3 sx={{ mb: 3, mt: 3 }}>Donation for SMSF India - HRC Mysuru Fund</h3>
        <p>
          <b>Welcome!</b> Thank you for choosing to donate to Sahaj Marg
          Spirituality Foundation, India. Sahaj Marg Spirituality Foundation
          supports all the events and programs undertaken by heartfulness. On
          completion of the donation, a confirmation email will be sent to you
          with a completed donation form attached.
          <b>
            Please forward this mail back to{" "}
            <a href="mailto:info.accounts@sahajmarg.org">
              info.accounts@sahajmarg.org
            </a>{" "}
            along with its attachment as your confirmation of the donation.
          </b>
        </p>
        <b>There are no refunds for the donations.</b>
        <br />
        <br />
        <PageContent eventCallback={eventCallback} pageContent={pageContent} />
      </Box>
    </LayoutHome>
  );
};

export default GeneralMysoreFund;
